<template>
	<div class="w-full h-full flex flex-col">
		<Header>
			<div>
				<h1>Avdelinger</h1>
				<h3 v-if="userDepartments">Totalt {{userDepartments.length}} {{userDepartments.length > 1 ? 'avdelinger' : 'avdeling'}}</h3>
			</div>
		</Header>
		
		<div class="flex-grow overflow-auto p-6 md:p-12">
			<div 
				v-if="userDepartments && userDepartments.length"
				class="grid gap-6 md:gap-12 grid-cols-1">
				
				<router-link 
					v-for="d in userDepartments"
					:to="{ name: 'portal-transactions', params: d }"
					:key="'department_'+d.departmentId">
					
					<DepartmentCard 
						:department="d"
					/>
				</router-link>
			</div>
			<div 
				v-else-if="!departments"
				class="h-full flex items-center justify-center">
				
				Avdelinger har ikke blitt lastet inn
			</div>
			<div 
				v-else
				class="h-full flex items-center justify-center">
				
				Din bruker er ikke medlem av noen avdelinger
			</div>
		</div>
		<div class="flex-none px-6 py-6 flex space-x-4">
			<router-link 
				type="submit"
				:to="{ 
					name: 'portal-users', 
					params: { 
						accountId: accountId,
					}
				}"
				:disabled="loading"
				class="button w-full">
				
				Avbryt
			</router-link>
		</div>
		
	</div>
</template>

<script>
	export default {
		components: {
			'DepartmentCard': () => import('@/components/departments/DepartmentCard.vue'),
			'DepartmentMessages': () => import('@/components/DepartmentMessages.vue'),
		},
		
		computed: {
			users(){
				return this.$store.getters.getUsers;
			},
			
			user(){
				return this.users.find( u => u.username == this.username);
			},
			
			departments(){
				return this.$store.getters.getDepartments.filter( d => !d.deletedAt);
			},
			
			userDepartments(){
				if ( !this.departments || !this.departments.length ) {
					return null;
				}
				
				if (this.user && this.user.departments ) {
					if ( this.user.departments == 'admin' ) { // return all departments if admin
						return this.departments;
					}
					else { // return only the participating departments
						return this.departments.filter( d => this.user.departments.includes( d.departmentId ) );
					}
				}
				
				return null;
			},
		},
		
		data(){
			return {
				loading: null,
				username: null,
				resetTimerIntervalFunction: null,
				showDeleted: false,
			}
		},
		
		methods: {
			onGoBack(){
				this.$router.push( { name: 'portal-users' } );
			},
		},
		
		async mounted(){
			this.username = this.$route.params.username;
			
			if ( this.userDepartments && this.userDepartments.length && this.userDepartments.length== 1 ) {
				// this.$router.push( { name: 'app-departments' } );
				// :to="{ name: 'portal-transaction', params: d }"
				console.log('redirect!');
				this.$router.push( { name: 'portal-transactions', params: this.userDepartments[0] } );
			}
			
			this.resetTimerIntervalFunction = setInterval( () => {
				this.$notify({ type: 'success', text: 'Gikk tilbake til start pga. inaktivitet' });
				this.$router.push( { name: 'portal-users' } );
			}, 1000 * 60 * 5);
		},
		
		beforeDestroy() {
			if (this.resetTimerIntervalFunction) {
				clearInterval(this.resetTimerIntervalFunction);
			}
		},
	}
</script>